import React from 'react'
import About from '../../components/About/about'
import Header from '../../components/Header/header_en'
import Footer from '../../components/Footer/footer'
import LayoutEn from '../../components/layoutEn'
import Helmet from 'react-helmet'

const AboutPage = () => (
    <LayoutEn>
         <Helmet
          title="VIP CARS - Rent a Car | Luxury Cars | Headquarters in Sarajevo"
          meta={[
            { name: 'description', content: 'VIP Cars - Rent a Car | Take over your favorite luxury car (vehicle) wherever you want in Bosnia, Serbia & Croatia. | T: +387 62 520 556.' },
          ]} >
        </Helmet>
        <div id="about">
    <div className="header-back"></div>
    <Header/>
    <section className="section about">
         <div className="container">
            <About/>
        </div>
   </section>
   <Footer/>
   </div>
   </LayoutEn>
)

export default AboutPage
